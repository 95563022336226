import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Header from '../components/header';

const IndexPage = ({ data }) => {
  let array = data.allAirtable.edges;

  array = array.sort(function (a, b) {
    return a.node.data.Type.localeCompare(b.node.data.Type);
  });

  array = array.sort(function (a, b) {
    return a.node.data.City.localeCompare(b.node.data.City);
  });

  return (
    <Layout>
      <Header
        crumbs={[
          {
            title: data.site.siteMetadata.title,
            location: null,
            active: true,
          },
        ]}
      />

      {array.map((edge, i) => (
        <Link
          to={edge.node.data.Path}
          key={i}
          className="flex items-center text-black font-bold m-1"
        >
          <svg
            className="fill-current w-3 h-3 mx-3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
          </svg>
          <h3>{edge.node.data.Title}</h3>
        </Link>
      ))}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allAirtable(filter: { table: { eq: "Sections" } }) {
      edges {
        node {
          data {
            Title
            Path
            City
            Type
          }
        }
      }
    }
  }
`;
